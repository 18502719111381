import React from "react";
import { Route } from "react-router-dom";
////////Sign Up
import MapSearch from "../Stylist/MapSearch";

export default function SearchStylist() {
  return (
    <div>
      {/* <h1>Search Stylist</h1> */}
      <MapSearch />
    </div>
  );
}
